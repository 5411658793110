import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ServiceProviderCard from "./ServiceProviderCard";
import { Context as SettingsContext } from "../../../context/DeploymentSettingsContext";

const SettingTab = ({ active }) => {
  const [ branches, setBranches ] = useState([])
  const {
    state: settingsState,
    getBranchSettings,
  } = useContext(SettingsContext);
  
  useEffect(() => {
    if (active) {
      getBranchSettings();
    }
  }, [ active ]);

  useEffect(() => {
    createOptions();
  }, [ settingsState.branchSettings ]);

  const createOptions = () => {
    if (settingsState.branchSettings.length > 0) {
      let data = joinDevelopments(settingsState.branchSettings);
      data = data.filter(a => a.developmentName === 'single-environment')

      setBranches(data);
    }
  };

  const joinDevelopments = (data) => {
    return data.reduce((acc, dev) => {
      acc.push(...dev.deployments.map(deployment => ({
        name: deployment.name,
        cognitoUserPool: deployment.cognitoUserPool,
        resourceGroupName: deployment.resourceGroupName,
        slotName: deployment.slotName,
        developmentName: dev.name
      })));
      return acc;
    }, []);
  }

  return (
      <>
        <div className="mt-2">
          {
            branches.map((branch, index) => {
              return <ServiceProviderCard branch={ branch } key={ index }/>
            })
          }
        </div>
      </>
  );
};

SettingTab.propTypes = {};

export default SettingTab;