import { Buffer } from 'buffer';
import { jwtDecode } from 'jwt-decode'
import config from '../config';

const processToken = (tokenInfo) => {
  const authUser = {
    tokenInfo: { ...tokenInfo },
    userInfo: null,
  };

  // Create expiresAt
  const currentTime = new Date().getTime();
  authUser.tokenInfo.expiresAt = currentTime + authUser.tokenInfo.expires_in * 1000;

  // Get user profile
  const decodedToken = jwtDecode(authUser.tokenInfo.id_token);
  authUser.userInfo = { ...decodedToken };

  return authUser;
};

const createAuthUser = async (authCode) => {
  // Get user tokens
  const body = new URLSearchParams([
    ['grant_type', 'authorization_code'],
    ['client_id', config.Auth.userPoolWebClientId],
    ['code', authCode],
    ['redirect_uri', config.Auth.oauth.redirectSignIn],
  ]).toString();

  const postOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: body,
  };
  const postResult = await fetch(
    config.tokenURL,
    postOptions
  );
  const rawToken = await postResult.json();

  const authUser = processToken(rawToken);

  // console.log(`authUser: ${JSON.stringify(authUser, null, 2)}`);
  localStorage.setItem('authUser', JSON.stringify(authUser));

  return authUser;
};

const isTokenExpired = () => {
  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const currentTime = new Date().getTime();
  var result = (currentTime >= authUser.tokenInfo.expiresAt);

  return result;
}

const refreshAuthUser = async () => {
  const localAuthUser = JSON.parse(localStorage.getItem('authUser'));

  const body = new URLSearchParams([
    ['grant_type', 'refresh_token'],
    ['client_id', config.Auth.userPoolWebClientId],
    ['refresh_token', localAuthUser.tokenInfo.refresh_token],
  ]).toString();

  const postOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded' 
    },
    body: body,
  };
  const postResult = await fetch(
    config.tokenURL,
    postOptions
  );
  const rawToken = await postResult.json();

  const authUser = processToken(rawToken);
  authUser.tokenInfo.refresh_token = localAuthUser.tokenInfo.refresh_token;

  localStorage.setItem('authUser', JSON.stringify(authUser));
};

const encodeBase64 = (data) => {
  return Buffer.from(data, 'utf8').toString('base64');
}
const decodeBase64 = (data) => {
  return Buffer.from(data, 'base64').toString('ascii');
}

const authHeader = async () => {
  if (isTokenExpired()) {
    await refreshAuthUser();
  }
  const authUser = JSON.parse(localStorage.getItem('authUser'));

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authUser.tokenInfo.access_token}` 
  }
};

export {
  createAuthUser,
  isTokenExpired,
  refreshAuthUser,
  encodeBase64,
  decodeBase64,
  authHeader,
};
