import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment/moment";

import { Modal, ModalHeader, ModalBody, ModalFooter, Badge, Input } from 'reactstrap';

const ResultDetailModal = ({ data, isOpen, toggle }) => {

  const displayStatus = (data) => {
    let color = 'info';
    let label = "";

    switch (data.status) {
      case 0:
        color = 'black';
        label = "None";
        break;
      case 1:
        color = 'primary';
        label = "Scheduled";
        break;
      case 2:
        color = 'warning';
        label = "Running";
        break;
      case 3:
        color = 'danger';
        label = "Error";
        break;
      case 4:
        color = 'secondary';
        label = "Invalid";
        break;
      case 5:
        color = 'info';
        label = "Skip";
        break;
      case 6:
        color = 'success';
        label = "Complete";
        break;
      default:
        break;
    }

    return <Badge
        className="px-3 py-1"
        color={ color }
        pill
    >
      { label }
    </Badge>
  }

  return (
      <Modal isOpen={ isOpen } toggle={ toggle }>
        <ModalHeader toggle={ toggle }>Result for: { data.firstName } { data.lastName }</ModalHeader>
        <ModalBody>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">Client Id:</span>
            <span className="text-bolder ps-4">{ data.clientId }</span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">First Name:</span>
            <span className="text-bolder ps-4">{ data.firstName }</span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">First Name:</span>
            <span className="text-bolder ps-4">{ data.lastName }</span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">Status:</span>
            <span className="text-bolder ps-4">
              { displayStatus(data) }
            </span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">Start Date:</span>
            <span
                className="text-bolder ps-4">{ moment.utc(data.startDate).local().format("DD-MMM-yyyy h:mm:ss a") }</span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">End Date:</span>
            <span
                className="text-bolder ps-4">{ moment.utc(data.endDate).local().format("DD-MMM-yyyy h:mm:ss a") }</span>
          </p>

          <p className={ "d-flex mb-1" }>
            <span className="text-muted">Created Date:</span>
            <span
                className="text-bolder ps-4">{ moment.utc(data.createdDate).local().format("DD-MMM-yyyy h:mm:ss a") }</span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">Updated Date:</span>
            <span
                className="text-bolder ps-4">{ moment.utc(data.updatedDate).local().format("DD-MMM-yyyy h:mm:ss a") }</span>
          </p>
          <p className={ "d-flex mb-1" }>
            <span className="text-muted">Result:</span>

          </p>
          <div>
            <Input type="textarea" rows="10" value={ data.result } readOnly/>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={ toggle }>Close</button>
        </ModalFooter>
      </Modal>
  );
};

ResultDetailModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ResultDetailModal;