import CreateContext from './createDataContext';
import { authHeader } from "../helpers/auth_helper";
import config from "../config";

const siteReducer = (state, action) => {
  switch (action.type) {
    case 'GET_SITES':
      return {
        ...state,
        sites: action.payload,
        error: null,
      };
    default:
      return state
  }
}

const getSites = (dispatch) => async (deployment) => {
  try {
    const headers = await authHeader();
    const options = {
      method: 'GET',
      headers,
      credentials: 'include',
    };
    const url = `${ config.adminURL }/site/${ deployment }`;
    const result = await fetch(
        url,
        options
    );
    if (result.ok === false) {
      throw new Error(result.statusText);
    }
    const sites = await result.json();
    dispatch({ type: 'GET_SITES', payload: sites });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'ERROR', payload: error });
  }
};

const getSitesRequest = async (deployment) => {
  const headers = await authHeader();
  const options = {
    method: 'GET',
    headers,
    credentials: 'include',
  };

  const url = `${ config.adminURL }/site/${ deployment }`;
  const result = await fetch(
      url,
      options
  );
  if (result.ok === false) {
    throw new Error(result.statusText);
  }
  
  return await result.json();
}

export const { Context, Provider } = CreateContext(
    siteReducer,
    {
      getSites
    },
    {
      sites: [],
      error: null,
    }
);

export {
  getSitesRequest
}