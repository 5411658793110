import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import { Card, CardTitle, CardHeader, CardBody, Button, Spinner, Badge } from "reactstrap"
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { reconciliationService } from "../../../services/reconciliation-service";
import ResultDetailModal from "./ResultDetailModal";
import CustomPagination from "../../../components/Common/CustomPagination"; // Optional Theme applied to the Data Grid

const ReconciliationDetail = ({ runId, deployment }) => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [ itemCount, setItemCount ] = useState(0)
  const [ pageCount, setPageCount ] = useState(0)
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(100)
  const [ gridFirstDataRendered, setGridFirstDataRendered ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ selectedResult, setSelectedResult ] = useState(null);
  const [ modalIsOpen, setModalIsOpen ] = useState(false)

  const viewButton = (props) => {
    const { data } = props
    return <Button size={ "sm" } color={ "primary" } onClick={ () => handleViewDetail(data) }>View Detail</Button>;
  };

  const handleViewDetail = (data) => {
    setModalIsOpen(true);
    setSelectedResult(data);
  }

  const displayStatus = (props) => {
    const { data } = props
    let color = 'info';
    let label = "";

    switch (data.status) {
      case 0:
        color = 'black';
        label = "None";
        break;
      case 1:
        color = 'primary';
        label = "Scheduled";
        break;
      case 2:
        color = 'warning';
        label = "Running";
        break;
      case 3:
        color = 'danger';
        label = "Error";
        break;
      case 4:
        color = 'secondary';
        label = "Invalid";
        break;
      case 5:
        color = 'info';
        label = "Skip";
        break;
      case 6:
        color = 'success';
        label = "Complete";
        break;
      default:
        break;
    }

    return <Badge
        className="px-3 py-1"
        color={ color }
        pill
    >
      { label }
    </Badge>
  }

  const [ rowData, setRowData ] = useState([]);
  const [ colDefs, setColDefs ] = useState([
    { field: "id" },
    { field: "clientId" },
    { field: "firstName" },
    { field: "lastName" },
    { field: "status", cellRenderer: displayStatus },
    { field: "runAsync" },
    { field: "startDate" },
    { field: "endDate" },
    { field: "runId" },
    { field: "result" },
    { field: "hashCode" },
    { field: "createdDate" },
    { field: "updatedDate" },
    { field: "action", cellRenderer: viewButton },
  ]);

  useEffect(() => {
    if (runId && deployment) {
      setLoading(true);
      reconciliationService.getPagingReconciliationRequest(deployment, {
        page,
        pageSize,
        runId
      }).then(res => {
        if (res.data) {
          const { data, itemCount, pageCount } = res.data;
          setItemCount(itemCount);
          setPageCount(pageCount);
          setRowData(data);
          setLoading(false);
        }
      }).catch(err => {
        setLoading(false);
      })
    }
  }, [ runId, page, pageSize ]);

  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (runId) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [ runId ]);

  return (
      <>
        <Card className="mb-2">
          <CardHeader className="bg-transparent border-bottom border-bottom-1">
            
            {/*<CardTitle>Reconciliation Details for { runId }</CardTitle>*/}
          </CardHeader>
          <CardBody>
            { loading ?
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Spinner
                      className="m-2"
                      color="primary"
                  >
                    Loading...
                  </Spinner>
                </div> : <div
                    className="ag-theme-quartz" // applying the Data Grid theme
                    style={ { height: 600 } } // the Data Grid will fill the size of the parent container
                >
                  <AgGridReact
                      ref={ gridRef } // Ref for accessing Grid's API
                      rowData={ rowData }
                      columnDefs={ colDefs }
                      onFirstDataRendered={ onFirstDataRendered }
                  />
                  <div className="mt-2">
                    <CustomPagination
                        itemCount={ itemCount }
                        currentPage={ page }
                        totalPage={ pageCount }
                        defaultPageSize={ pageSize }
                        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                        onChangePage={ i => setPage(i) }
                        onPageSizeChange={ size => {
                          setPage(1);
                          setPageSize(size);
                        } }
                    />
                  </div>
                </div> }
          </CardBody>
        </Card>
        {
            selectedResult &&
            <ResultDetailModal
                data={ selectedResult }
                isOpen={ modalIsOpen }
                toggle={ () => setModalIsOpen(false) }

            />
        }
      </>
  );
};

ReconciliationDetail.propTypes = {};

export default ReconciliationDetail;