import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row, Alert } from 'reactstrap';

import SiteFilterCatchment from './SiteFilterCatchment';

const SiteFilterModal = ({ isOpen, toggle, title, selectedSites, onSubmit, siteOptions }) => {
  const [sites, setSites] = useState([]);
  const [catchments, setCatchments] = useState([]);
  const [allSiteChecked, setAllSiteChecked] = useState(false);

  useEffect(() => {}, [sites, catchments, allSiteChecked]);

  const handleOnCheckboxSiteChange = (e, item) => {
    const { checked } = e.target;
    setSites(
        sites.map(site => {
          if (site.id === item.id) {
            return checked ? { ...item, isChecked: true } : { ...item, isChecked: false };
          }
          return site;
        })
    );
  };

  const handleOnCheckboxCatchmentChange = (e, item) => {
    const { checked } = e.target;
    setCatchments(
        catchments.map(catchment => {
          if (catchment.id === item.id) {
            return checked ? { ...catchment, isChecked: true } : { ...catchment, isChecked: false };
          }
          return catchment;
        })
    );

    setSites(
        sites.map(site => {
          if (site.catchmentId === item.id) {
            return checked ? { ...site, isChecked: true } : { ...site, isChecked: false };
          }
          return site;
        })
    );
  };

  const handleOnCheckboxAllSiteChange = e => {
    const { checked } = e.target;
    setAllSiteChecked(checked);
    setSites(
        sites.map(site => {
          return checked
              ? {
                ...site,
                isChecked: true,
              }
              : {
                ...site,
                isChecked: false,
              };
        })
    );
    setCatchments(
        catchments.map(catchment => {
          return checked ? { ...catchment, isChecked: true } : { ...catchment, isChecked: false };
        })
    );
  };

  const handleFilter = () => {
    onSubmit(
        sites
            .filter(e => e.isChecked)
            .map(a => {
              return a;
            })
    );
  };

  const onOpened = () => {
    if (selectedSites && selectedSites.length > 0) {
      let catchmentIds = [...new Set(siteOptions.map(a => a?.catchment?.id))];
      let selectedCatchmentIds = [...new Set(selectedSites.map(a => a?.catchment?.id))];
      setCatchments(
          catchmentIds.map(catchmentId => {
            let selectedCatchmentId = selectedCatchmentIds.find(e => e === catchmentId);
            if (selectedCatchmentId) {
              let catchment = siteOptions.find(e => e.catchmentId === selectedCatchmentId).catchment;
              return { ...catchment, isChecked: true };
            } else {
              let catchment = siteOptions.find(e => e.catchmentId === catchmentId).catchment;
              return { ...catchment, isChecked: false };
            }
          })
      );

      setSites(
          siteOptions.map(a => {
            let selectedSite = selectedSites.find(e => e.id === a.id);
            if (selectedSite) {
              return { ...a, isChecked: true };
            } else {
              return { ...a, isChecked: false };
            }
          })
      );
      setAllSiteChecked(false);
    } else {
      let catchmentIds = [...new Set(siteOptions.map(a => a?.catchment?.id))];
      setCatchments(
          catchmentIds.map(catchmentId => {
            let catchment = siteOptions.find(e => e.catchmentId === catchmentId).catchment;
            return { ...catchment, isChecked: true };
          })
      );
      setSites(
          siteOptions.map(a => {
            return { ...a, isChecked: true };
          })
      );
      setAllSiteChecked(true);
    }
  };

  var selectedSitesCount = sites.filter(e => e.isChecked).length;

  return (
      <Modal size="lg" onOpened={onOpened} isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalHeader className="py-3" toggle={toggle}>
          Filter by Site
        </ModalHeader>
        {/* <div style={{ position: 'absolute', top: '15px', right: '20px', zIndex: '1' }}>
        <button onClick={toggle} type="button" className="close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div> */}
        <ModalBody className="tooltip-label-right py-3 px-4">
          {selectedSitesCount < 1 && (
              <Row>
                <Col md={12}>
                  <Alert color="danger">
                    <p className="mb-0">
                      You must filter by at least one site.
                    </p>
                  </Alert>
                </Col>
              </Row>
          )}
          <Row>
            <Col md={12}>
              <div className="text-end">
                <Input
                    type="checkbox"
                    id={ 'allSites' }
                    checked={ allSiteChecked }
                    onChange={ handleOnCheckboxAllSiteChange }
                    readOnly
                />
                <label className="custom-control-label ps-2" htmlFor="catchment-1">All Sites</label>
              </div>
            </Col>
          </Row>
          <Row>
            { catchments.map((catchment, index) => {
              return (
                  <Col key={index} md={12}>
                    <SiteFilterCatchment
                        item={catchment}
                        sites={sites.filter(e => e.catchmentId === catchment.id)}
                        onCheckboxCatchmentChange={handleOnCheckboxCatchmentChange}
                        onCheckboxSiteChange={handleOnCheckboxSiteChange}
                    />
                  </Col>
              );
            })}
          </Row>
          <div className="d-flex justify-content-end">
            <Button
                color="primary"
                size="sm"
                type="submit"
                onClick={handleFilter}
                disabled={selectedSitesCount < 1}
            >
              Filter
            </Button>
          </div>
        </ModalBody>
      </Modal>
  );
};

export default SiteFilterModal;
