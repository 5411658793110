import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardTitle, CardBody, Spinner } from "reactstrap"
import SiteFilterModal from "../../../containers/SiteFilterModal";
import { reconciliationService } from "../../../services/reconciliation-service";


const ServiceProviderCard = ({ branch }) => {
  const [ modalFilter, setModalFilter ] = useState(false)
  const [ siteOptions, setSiteOptions ] = useState([])
  const [ selectedSites, setSelectedSites ] = useState([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if (branch) {
      populateData();
    }
  }, [ branch ])

  const populateData = () => {
    setLoading(true);
    // reconciliationService.getPagingRunsRequest(branch.name, {}).then(res => {
    //   if(res.data){
    //     console.log(res.data);
    //   }
    //  
    // }).catch(err => {
    //       console.log(err);
    //  
    //     }
    // )
    reconciliationService.getSettingRequest(branch.name).then(data => {
      const { sites, settings } = data;
      let selectedSites = settings.map(a => a.site);
      setSiteOptions(sites);
      setSelectedSites(selectedSites);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setLoading(false)
    })
  }

  const handleOnSubmitFilter = (sites) => {
    setSelectedSites(sites)
    let siteIds = sites.map(site => site.id);
    reconciliationService.updateSettingRequest(branch.name, siteIds).then(data => {
      console.log(data);
      populateData();

      setModalFilter(false);
    }).catch(err => {
          console.log(err);
          setModalFilter(false);
        }
    )
  }

  return (
      <>

        <Card className="border-radius mb-2">
          <CardHeader className="bg-transparent border-bottom border-bottom-1">
            <div className="d-flex align-items-center">
              <CardTitle className={ "mb-0 pb-0" }>{ branch.name }</CardTitle>
              <span onClick={ () => setModalFilter(true) } className="bx bx-cog ms-3 px-2 py-2"
                    style={ { fontSize: '20px', cursor: 'pointer' } }></span>
            </div>
          </CardHeader>
          <CardBody>
            { loading ? <div className="d-flex justify-content-center align-items-center flex-column">
                  <Spinner
                      className="m-2"
                      color="primary"
                  >
                    Loading...
                  </Spinner>
                </div> :
                <>
                  <p>
                    <strong>
                      Filtered Sites:
                    </strong>
                    { selectedSites &&
                        selectedSites.map((site, index) => {
                          return (
                              <span key={ index }>
              { index > selectedSites.length - 2 ? (
                  <>
                  <span key={ index } className="mx-2">
                    { site.code } - { site.name }
                  </span>
                  </>
              ) : (
                  <>
                  <span key={ index } className="mx-2">
                    { site.code } - { site.name }
                  </span>
                    |
                  </>
              ) }
            </span>
                          );
                        }) }
                  </p>
                </>
            }

          </CardBody>
        </Card>
        <SiteFilterModal
            isOpen={ modalFilter }
            selectedSites={ selectedSites }
            siteOptions={ siteOptions }
            toggle={ () => setModalFilter(false) }
            onSubmit={ handleOnSubmitFilter }
        />
      </>
  );
};

ServiceProviderCard.propTypes = {};

export default ServiceProviderCard;