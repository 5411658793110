import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import SingleSignOn from '../pages/Authentication/SingleSignOn.js';

// Dashboard
import DeploymentsDashboard from "../pages/Dashboard/Deployments";
import DevOpsMain from "pages/Dashboard/DevOps";
import Reconciliation from "../pages/Dashboard/Reconciliation";

const authProtectedRoutes = [
  { path: "/dashboard/deployments", component: <DeploymentsDashboard /> },
  { path: "/dashboard/devops", component: <DevOpsMain /> },
  { path: "/dashboard/reconciliation", component: <Reconciliation /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard/deployments" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/sso", component: <SingleSignOn /> },
];

export { authProtectedRoutes, publicRoutes };
