import React from 'react';
import { Card, CardBody, Col, Input, Row } from 'reactstrap';

const SiteFilterCatchment = ({ item, sites, onCheckboxCatchmentChange, onCheckboxSiteChange }) => {
  return (
      <div className="mb-3">
        {item && (
            <div className="mb-2">
              <Input
                  id={ `catchment-${ item.id }` }
                  type="checkbox"
                  checked={ item.isChecked }
                  onClick={ e => onCheckboxCatchmentChange(e, item) }
                  readOnly
              />
              <label className="custom-control-label ps-2" htmlFor="catchment-1">{item?.description}</label>
            </div>
        ) }
        <Card>
          <CardBody
              style={ {
                borderLeft: '20px solid #145388',
                borderTop: '1px solid #145388',
                borderBottom: '1px solid #145388',
                borderRight: '1px solid #145388',
              }}
          >
            <Row>
              {sites.map((site, index) => {
                return (
                    <Col key={index} md={4}>
                      <div className="mb-2">
                        <div className="pretty p-default">
                          <input
                              type="checkbox"
                              id={`site${site.id}`}
                              name={`site${site.id}`}
                              checked={site.isChecked}
                              onChange={e => onCheckboxSiteChange(e, site)}
                          />
                          <div className={`state p-primary`}>
                            <label className="ml-1">{site?.code}</label>
                          </div>
                        </div>
                        <div className="ml-4 ">
                          <span className="mb-0 text-muted">{site.name}</span>
                        </div>
                      </div>
                    </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </div>
  );
};

export default SiteFilterCatchment;
