import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from 'classnames';
import { Provider as SiteProvider } from "../../../context/SiteContext";
import SettingTab from "./SettingTab";
import RunsTab from "./RunsTab";
import {
  Provider as ActionsProvider,
} from 'context/DeploymentActionsContext';


const ReconciliationMain = props => {
  const [currentActiveTab, setCurrentActiveTab] = useState(1);

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }


  return (
      <Container>
        <Row>
          <Col>
            <div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                      className={ classnames({
                        active: currentActiveTab === 1
                      }) }
                      onClick={ () => {
                        toggle(1);
                      } }
                  >
                    Runs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={ classnames({
                        active: currentActiveTab === 2
                      }) }
                      onClick={ () => {
                        toggle(2);
                      } }
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={ currentActiveTab }>
                <TabPane tabId={ 1 }>
                    <RunsTab active={currentActiveTab === 1}/>
                </TabPane>
                <TabPane tabId={ 2 }>
                  <SiteProvider>
                    <SettingTab active={currentActiveTab === 2}/>
                  </SiteProvider>
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
  );
};

ReconciliationMain.propTypes = {};

export default ReconciliationMain;